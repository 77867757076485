import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter
} from "react-router-dom";
import VisitorRegistration from './Components/Visiterregistration/visiterregistration';
import ViewVisitor from './Components/Visiterregistration/viewvisitor';
import Login from './Components/Login/Login';
import ConferenceBooking from './Components/ConferenceBooking/ConferenceBooking';
import Userdetails from './Components/Userdetils/Userdetails';
import Protected from './Components/Protected';
import ViewConferenceBooking from './Components/ConferenceBooking/ViewConferenceBooking';
import Payamount from './Components/Checkout/PayAmount';
import { PaymentSuccess } from './Components/Checkout/Payment/PaymentSuccess';
import Visiterthankyou from './Components/Visiterregistration/Visiterthankyou';

function App() {

  // Auto Ev Expo
  return (
    <BrowserRouter>
    <div>
       
  
        <Routes>
          <Route
            exact
            path="/"
            element={<VisitorRegistration />}
          ></Route>
          <Route
            exact
            path="/Whitepaper"
            element={<VisitorRegistration />}
          ></Route>
           <Route
            exact
            path="/ConferenceBooking"
            element={<ConferenceBooking />}
          ></Route>
          <Route
            exact
            path="/Payamount"
            element={<Payamount />}
          ></Route>
           <Route
            exact
            path="/PaymentSuccess"
            element={<PaymentSuccess />}
          ></Route>
          <Route
            exact
            path="/Visiterthankyou"
            element={<Visiterthankyou />}
          ></Route>


          <Route
            exact
            path="/ViewSubscribers" element={
              <>
                <Protected Cmp={ViewVisitor} />

              </>}>
          </Route>

          <Route
            exact
            path="/Login"
            element={<Login />}
          ></Route>
        </Routes>

  
      
    </div>
    </BrowserRouter>
  );
}

export default App;
