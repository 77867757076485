import React,{ useState,useEffect} from 'react';
import {
   MDBContainer,
  MDBRow,
  MDBCol,
}
from 'mdb-react-ui-kit';
import Banner from "../Assets/banner_2.png";
import { useNavigate } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';


 function VisiterRegistration() {

  
  const createUserApi = `${process.env.REACT_APP_API_URL}auth/whitepaperDataSave`
  const navigate = useNavigate();
    const [error, setError] = useState([]);
    

    useEffect(()=>{
      document.title="Register";
    },[]);
  

    // name, jobprofile, companyname,phone,email,city,referby,message
    const [user, setUser] = useState({
        firstname: "",
        lastname: "",
        companyname: "",
        phone: "",
        email: "",
        countryname: "",
        jobrole: "",
        industry:""
    });

    const handelInput = (event) => {
      event.preventDefault();
      const { name, value } = event.target;
      console.log(name, value)
      setUser({ ...user, [name]: value });
  }


  const validateForm = () => {
    let isValid = true;
    const newErrors = [];

    // Validate password
    if (!user.firstname) {
      newErrors.firstname = "First Name is required";
      isValid = false;
    }
    if (!user.lastname) {
      newErrors.lastname = "Last Name is required";
      isValid = false;
    }
    if (!user.jobrole) {
      newErrors.jobrole = "Select Job Role is required";
      isValid = false;
    }
   
  
    if (!user.email) {
      newErrors.email = "Email Address is required";
      isValid = false;
    }
   
    if (!user.industry) {
      newErrors.industry = "Select Industry is required";
      isValid = false;
    }
   

    setError(newErrors);
    return isValid;
  };


  const handelSubmit = async (event) => {
    event.preventDefault();
    if(!validateForm())
    {
      
      //console.log(error);
    }
    else{
    //console.log(user)
    try {
       
        const response = await fetch(createUserApi, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(user),
        });

        if (response.ok) {
           // alert("Thank you for Registration");
            console.log('Form submitted successfully!');
            navigate('/Visiterthankyou',{state:user});
           
           
        } else {
            console.error('Form submission failed!');
        }

    } catch (error) {
        setError(error.message);
    } finally{
        
    }
  }
}



  return (
    <div>
       <Header />
    
    <MDBContainer className="my-5 gradient-form">
      
      <MDBRow>
       
        <MDBCol col='8' className="mb-8">
        
          <div className="d-flex flex-column ms-5">


          <div className='user-form'>
            <div className='heading'>
              
            <h2>Subscribe</h2>
            </div>
            <form onSubmit={handelSubmit}>
            <div className='shadow-sm p-3 mb-5 bg-light rounded mr-bottom '>

            <div className="mb-3">
                    <label for="email" className="form-label required">Email Address</label>
                    <input type="email" className="form-control" id="email" name="email" value={user.email}  onChange={handelInput} />
                    {error.email && <div className="error">{error.email}</div>}
                </div>


                <MDBRow>
                <MDBCol col='6'>
                <div className="mb-3">
                    <label for="firstname" className="form-label required">First Name</label>
                    <input type="text" className="form-control" id="firstname" name="firstname" value={user.firstname}   onChange={handelInput} />
                    {error.firstname && <div className="error">{error.firstname}</div>}
                </div>
                </MDBCol>

                <MDBCol col='6'>
                <div className="mb-3">
                    <label for="lastname" className="form-label required">Last Name</label>
                    <input type="text" className="form-control" id="lastname" name="lastname" value={user.lastname}   onChange={handelInput} />
                    {error.lastname && <div className="error">{error.lastname}</div>}
                </div>
                </MDBCol>
              </MDBRow>

                

              
              <MDBRow>
                <MDBCol col='6'>
                <div className="mb-3">

                   <label for="countryname" className="form-label">Country/Region:</label>
                    <input type="text" className="form-control" id="countryname" name="countryname" value={user.countryname}   onChange={handelInput} />
                    {error.countryname && <div className="error">{error.countryname}</div>}
                    </div>
                    </MDBCol>
                   <MDBCol col='6'>
                   <div className="mb-3">
                    <label for="companyname" className="form-label">Company Name</label>
                    <input type="text" className="form-control" id="companyname" name="companyname" value={user.companyname}   onChange={handelInput} />
                    {error.companyname && <div className="error">{error.companyname}</div>}
                </div>
                </MDBCol>
              </MDBRow>


              <MDBRow>
                <MDBCol col='6'>
                <div className="mb-3">
                    <label for="phone" className="form-label">Mobile phone number:</label>
                    <input type="text" className="form-control" id="phone" name="phone" value={user.phone}   onChange={handelInput} />
                    {error.phone && <div className="error">{error.phone}</div>}
                </div>
                </MDBCol>
                   <MDBCol col='6'>
               
                <div className="mb-3">
                    <label for="referby" className="form-label">Job Role: </label>
                    <select className="form-control form-select" id="jobrole" name="jobrole"   onChange={handelInput}>
                    <option value="">--Select--</option>
                    <option value="R&D">R&D</option>
                    <option value="Engineer & Technical Support">Engineer & Technical Support</option>
                    <option value="Management">Management</option>
                    <option value="Marketing & Sales">Marketing & Sales</option> 
                    <option value="Manufacturing">Manufacturing</option> 
                    <option value="Purchasing">Purchasing</option>
                    <option value="Quality & Supply Chain">Quality & Supply Chain</option>
                    <option value="Media & Consultant">Media & Consultant</option>
                    <option value="Education & Academic">Education & Academic</option>
                    <option value="Other">Other</option>
                    </select>
                    {error.jobrole && <div className="error">{error.jobrole}</div>}
                    {/* <input type="text" className="form-control" id="referby" name="referby"  onChange={handelInput} /> */}
                </div>
                </MDBCol>
              </MDBRow>

                <div className="mb-3">
                    <label for="industry" className="form-label">Industry: </label>
                    <select className="form-control form-select" id="industry" name="industry"   onChange={handelInput}>
                      <option value="">--Select--</option>
                      <option value="Automotive">Automotive</option>
                      <option value="Avionics, Military & Space">Avionics, Military & Space</option>
                      <option value="Computer">Computer</option>
                      <option value="Consumer">Consumer</option>
                      <option value="Industrial">Industrial</option>
                      <option value="Medical">Medical</option>
                      <option value="Telecommunications">Telecommunications</option>
                      <option value="Distribution Partner">Distribution Partner</option>
                      <option value="Electronics Manufacturing Services">Electronics Manufacturing Services</option>
                      <option value="Education & Academic"> Education & Academic</option>
                      <option value="Other">Other</option>
                    </select>
                    {error.industry && <div className="error">{error.industry}</div>}
                    {/* <input type="text" className="form-control" id="referby" name="referby"  onChange={handelInput} /> */}
                </div>

            
                
                
                                 
                <button type="submit" className="btn btn-primary submit-btn">Subscribe</button>
                </div>
            </form>
        </div>

          </div>

        </MDBCol>

      </MDBRow>

    </MDBContainer> 

    <div className='container-fluid bg-green'>
            <Footer/>
            </div>
    </div>
  )
}

export default VisiterRegistration;